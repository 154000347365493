import { useAppContext } from '@context/app';
import { CheckIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import React from 'react';
import cn from "classnames";

interface ProgressBarProps {
  value: number;
  maxValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, maxValue }) => {
  const percentage = (value / maxValue) * 100;
  const { currency } = useAppContext()
  const { locale } = useRouter()

  return (
    <div dir={locale?.includes("ar-") ? "rtl" : "ltr"} className=' w-full flex gap-6 items-center'>
      <div className=' flex-1 relative'>
        <div className="w-full h-1 bg-[#DDDDDD] rounded-full overflow-hidden">
          <div
            className="h-full bg-brandBlue rounded-full transition-all ease-in-out duration-200"
            style={{ width: `${percentage >= 100 ? 100 : percentage}%` }}
          ></div>
        </div>
        {percentage >= 100 ? <div className={cn('w-5 h-5 rounded-full bg-brandBlue absolute z-1  -top-[8px] flex items-center justify-center', locale?.includes("ar-") ? "left-0" : "right-0")}>
          <CheckIcon className=' text-white w-4' />
        </div> : null}
      </div>
      <div dir='ltr' className=' text-brandBlue text-sm font-normal flex space-x-1'>
        <bdi >{currency}</bdi>
        <span>{maxValue}</span>
      </div>
    </div>

  );
};

export default ProgressBar;

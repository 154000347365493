import { COOKIE_LOGIN_TOKEN_KEY } from "@utils/constants";
import { getCookie } from "@utils/Cookies";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import {
  CartItemModel,
  IAddressFieldsInput,
  IAreaDetailInput,
  ICountryInput,
  IFromEvents,
  ISyncInput,
  IUserProfileInput,
} from "@types";
import { setClientAuthInterceptorCallback } from "@https/api";

interface IAppState {
  user: {
    isLoggedIn: boolean;
    data?: IUserProfileInput;
  };
  cart: CartItemModel[];
  oldCart: CartItemModel[];
  country: ICountryInput | undefined;
  area?: IAreaDetailInput;
  currency: string;
  currency_iso: string;
  decimal: number;
  walletBalance: number;
  deliveryAddress: IAddressFieldsInput | undefined;
  oldDeliveryAddress: IAddressFieldsInput | undefined;
  eventProperties: IFromEvents;
  syncData?: ISyncInput;
  freeShipping: null | number;
}

type Functions = {
  setUserLoggedIn: (data: { isLoggedIn: boolean; data?: IUserProfileInput }) => void;
  setUserCart: (data: CartItemModel[]) => void;
  setUserOldCart: (data: CartItemModel[]) => void;
  setUserCountry: (data: ICountryInput | undefined) => void;
  setUserFreeShipping: (data: number | null) => void;
  setUserArea: (data: IAreaDetailInput) => void;
  setUserCurrency: (data: string) => void;
  setUserCurrencyIso: (data: string) => void;
  setUserDecimal: (data: number) => void;
  setUserWalletBalance: (data: number) => void;
  setUserDeliveryAddress: (data: IAddressFieldsInput | undefined) => void;
  setUserOldDeliveryAddress: (data: IAddressFieldsInput | undefined) => void;
  setEventProperties: (data: IFromEvents) => void;
  setSyncData: (data: ISyncInput) => void;
};
type AppContextType = IAppState & Functions;

type Action = {
  type:
    | "SET_USER_IS_LOGGED_IN"
    | "SET_USER_CART"
    | "SET_USER_OLD_CART"
    | "SET_USER_COUNTRY"
    | "SET_USER_AREA"
    | "SET_USER_CURRENCY"
    | "SET_USER_CURRENCY_ISO"
    | "SET_USER_DECIMAL"
    | "SET_USER_WALLET_BALANCE"
    | "SET_USER_DELIVERY_ADDRESS"
    | "SET_USER_OLD_DELIVERY_ADDRESS"
    | "SET_EVENT_PROPERTIES"
    | "SET_USER_FREE_SHIPPING"
    | "SET_SYNC_DATA";
  payload: any;
};

const initialState: AppContextType = {
  user: { isLoggedIn: getCookie().get(COOKIE_LOGIN_TOKEN_KEY) ? true : false },
  cart: [],
  oldCart: [],
  country: undefined,
  area: undefined,
  currency: "KD",
  currency_iso: "KD",
  decimal: 2,
  walletBalance: 0,
  deliveryAddress: undefined,
  oldDeliveryAddress: undefined,
  eventProperties: {
    from_page: "",
    from_entity: "",
    from_entity_slug: "",
    from_page_section: "",
    from_page_section_entity: "",
    from_page_section_slug: "",
  },
  syncData: undefined,
  freeShipping: null,
  setUserLoggedIn: () => {
    console.log("");
  },
  setUserCart: () => {
    console.log("");
  },
  setUserOldCart: () => {
    console.log("");
  },
  setUserCountry: () => {
    console.log("");
  },
  setUserArea: () => {
    console.log("");
  },
  setUserCurrency: () => {
    console.log("");
  },
  setUserCurrencyIso: () => {
    console.log("");
  },
  setUserDecimal: () => {
    console.log("");
  },
  setUserWalletBalance: () => {
    console.log("");
  },
  setUserDeliveryAddress: () => {
    console.log("");
  },
  setUserOldDeliveryAddress: () => {
    console.log("");
  },
  setEventProperties: () => {
    console.log("");
  },
  setSyncData: () => {
    console.log("");
  },
  setUserFreeShipping: () => {
    console.log("");
  },
};

const AppContext = createContext(initialState);

function appReducer(state: AppContextType, action: Action) {
  switch (action.type) {
    case "SET_USER_IS_LOGGED_IN": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "SET_USER_CART": {
      return {
        ...state,
        cart: action.payload,
      };
    }
    case "SET_USER_OLD_CART": {
      return {
        ...state,
        oldCart: action.payload,
      };
    }
    case "SET_USER_COUNTRY": {
      return {
        ...state,
        country: action.payload,
      };
    }
    case "SET_USER_AREA": {
      return {
        ...state,
        area: action.payload,
      };
    }
    case "SET_USER_CURRENCY": {
      return {
        ...state,
        currency: action.payload,
      };
    }
    case "SET_USER_CURRENCY_ISO": {
      return {
        ...state,
        currency_iso: action.payload,
      };
    }
    case "SET_USER_DECIMAL": {
      return {
        ...state,
        decimal: action.payload,
      };
    }
    case "SET_USER_WALLET_BALANCE": {
      return {
        ...state,
        walletBalance: action.payload,
      };
    }
    case "SET_USER_DELIVERY_ADDRESS": {
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    }
    case "SET_USER_OLD_DELIVERY_ADDRESS": {
      return {
        ...state,
        oldDeliveryAddress: action.payload,
      };
    }
    case "SET_EVENT_PROPERTIES": {
      return {
        ...state,
        eventProperties: action.payload,
      };
    }
    case "SET_SYNC_DATA": {
      return {
        ...state,
        syncData: action.payload,
      };
    }
    case "SET_USER_FREE_SHIPPING": {
      return {
        ...state,
        freeShipping: action.payload,
      };
    }
  }
}

AppContext.displayName = "AppContext";

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const setUserLoggedIn = useCallback(
    (payload: { isLoggedIn: boolean; data?: IUserProfileInput }) =>
      dispatch({ type: "SET_USER_IS_LOGGED_IN", payload }),
    []
  );
  const setUserCurrency = useCallback(
    (payload: string) => dispatch({ type: "SET_USER_CURRENCY", payload }),
    []
  );
  const setUserCurrencyIso = useCallback(
    (payload: string) => dispatch({ type: "SET_USER_CURRENCY_ISO", payload }),
    []
  );
  const setUserDecimal = useCallback(
    (payload: number) => dispatch({ type: "SET_USER_DECIMAL", payload }),
    []
  );
  const setUserWalletBalance = useCallback(
    (payload: number) => dispatch({ type: "SET_USER_WALLET_BALANCE", payload }),
    []
  );
  const setUserCart = useCallback(
    (payload: CartItemModel[]) => [
      window.localStorage.setItem("DABDOOB_CART", JSON.stringify(payload)),
      dispatch({ type: "SET_USER_CART", payload }),
    ],
    []
  );
  const setUserOldCart = useCallback(
    (payload: CartItemModel[]) => [dispatch({ type: "SET_USER_OLD_CART", payload })],
    []
  );
  const setUserCountry = useCallback(
    (payload: ICountryInput | undefined) => dispatch({ type: "SET_USER_COUNTRY", payload }),
    []
  );
  const setUserArea = useCallback(
    (payload: IAreaDetailInput) => dispatch({ type: "SET_USER_AREA", payload }),
    []
  );
  const setUserDeliveryAddress = useCallback(
    (payload: IAddressFieldsInput | undefined) => [
      dispatch({ type: "SET_USER_DELIVERY_ADDRESS", payload }),
    ],
    []
  );
  const setUserOldDeliveryAddress = useCallback(
    (payload: IAddressFieldsInput | undefined) =>
      dispatch({ type: "SET_USER_OLD_DELIVERY_ADDRESS", payload }),
    []
  );
  const setEventProperties = useCallback(
    (payload: IFromEvents) => dispatch({ type: "SET_EVENT_PROPERTIES", payload }),
    []
  );

  const setSyncData = useCallback(
    (payload: ISyncInput) => dispatch({ type: "SET_SYNC_DATA", payload }),
    []
  );

  const setUserFreeShipping = useCallback(
    (payload: number | null) => dispatch({ type: "SET_USER_FREE_SHIPPING", payload }),
    []
  );

  const value = useMemo(
    () => ({
      ...state,
      setUserLoggedIn,
      setUserCart,
      setUserOldCart,
      setUserCountry,
      setUserCurrency,
      setUserCurrencyIso,
      setUserDecimal,
      setUserWalletBalance,
      setUserDeliveryAddress,
      setUserOldDeliveryAddress,
      setUserArea,
      setEventProperties,
      setSyncData,
      setUserFreeShipping,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  useEffect(() => {
    const data = window.localStorage.getItem("DABDOOB_CART");
    if (data) {
      setUserCart(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    setClientAuthInterceptorCallback(() => {
      if (!getCookie().get(COOKIE_LOGIN_TOKEN_KEY)) {
        setUserLoggedIn({ isLoggedIn: false });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`useAppContext must be used within a AppProvider`);
  }
  return context;
}

export default AppProvider;

import { useAppContext } from "@context/app";
import ProgressBar from "./progressBar";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { useRouter } from "next/router";

interface FreeDeliveryProps {

}

const FreeDelivery: React.FC<FreeDeliveryProps> = () => {
  const { currency, cart, freeShipping, setUserFreeShipping, decimal } = useAppContext()
  const { locale } = useRouter()
  const t = useTranslations("");
  useEffect(() => {
    for (const item of cart) {
      if (!item.raw_price) {
        setUserFreeShipping(null)
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])


  const totalPrice = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty * (currentValue.raw_price || 0);
  }, 0);
  const maxValue = freeShipping || 0
  const remaining = (maxValue - totalPrice).toFixed(decimal)
  const isFree = totalPrice >= maxValue ? true : false
  return (
    freeShipping && cart.length ? <div className="w-full max-w-[771px] bg-[#F8F8F8] rounded-xl p-4 flex flex-col gap-2 items-center mx-auto">
      {isFree ? <bdi className=" text-textDark text-sm font-normal">{t("client_productdetails_freedeliveryunlocked")}</bdi> : <bdi className=" text-textDark text-sm font-normal">{t("client_productdetails_freedelivery")}</bdi>}
      {isFree ? <bdi className=" text-textLight text-sm font-medium">{t("client_productdetails_freedeliveryenjoy")}</bdi> : <bdi className=" text-textLight text-sm font-medium">{t("client_productdetails_freedeliveryremaining").replace("@", locale?.includes("ar-") ? `${remaining} ${currency}` : `${currency} ${remaining}`)}</bdi>}
      <div className="w-full mt-2">
        <ProgressBar maxValue={maxValue} value={totalPrice} />
      </div>

    </div> : null
  );
}

export default FreeDelivery;
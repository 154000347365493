import Close from "@svg/close";
import { IAreaDetailInput, IAreaInput } from "@types";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React, { useState } from "react";
import ParentArea from "./area/parentArea";
import ChildArea from "./area/childArea";
import Button from "./button";
import SearchArea from "./area/searchArea";
interface AreaViewProps {
  action: (data: IAreaDetailInput | IAreaInput) => void;
  areas: IAreaInput[];
  areaType?: 0 | 1 | 2
}
const AreaView: React.FC<AreaViewProps> = ({ action, areas, areaType }) => {
  const t = useTranslations("");
  const { locale } = useRouter();
  // const [areaList, setAreaList] = useState<IAreaInput[]>([]);
  // const [searchResult, setSearchResult] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<IAreaInput | IAreaDetailInput | undefined>(undefined);

  // useEffect(() => {
  //   setAreaList(areas);
  // }, [areas]);
  // const handelSearch = (data: string) => {
  //   setSearchValue(data);
  //   if (data.length >= 1) {
  //     const list = [];
  //     for (const area of areas) {
  //       for (const child of area.items) {
  //         if (child.name.toLowerCase().includes(data.toLowerCase())) {
  //           list.push({ ...child, parent: area.name });
  //         }
  //       }
  //     }
  //     setSearchResult(list);
  //   } else {
  //     setSearchResult([]);
  //   }
  // };
  // let resultContent;
  // if (searchResult.length) {
  //   resultContent = searchResult.map((area, index) => (
  //     <div
  //       className={`w-full flex items-center cursor-pointer px-2 h-12 ${index + 1 === searchResult.length ? "" : "border-b"}`}
  //       key={`${area.name}--${area.id}`}
  //       onClick={() => action({ ...area, parentName: area.parent })}
  //     >
  //       <p className="text-left text-[#999]  text-sm font-normal whitespace-nowrap text-ellipsis overflow-hidden">
  //         <span className=" text-textDark font-bold">{area.name}</span> in {area.parent}
  //       </p>
  //     </div>
  //   ));
  // } else {
  //   resultContent = (
  //     <div className="w-full flex flex-col items-center justify-center min-h-[250px]">
  //       <AddressesIcon className=" stroke-textLight mb-3" />
  //       <p className="text-textLight text-sm font-normal w-[220px] leading-6">
  //         {t("client_area_nodatamessage")}
  //       </p>
  //     </div>
  //   );
  // }
  console.log(selectedArea)
  return (
    <div className="w-full  rounded-xl overflow-hidden h-full flex flex-col">
      <div className=" w-full relative mb-3">
        <input
          dir={locale?.includes("ar-") ? "rtl" : "ltr"}
          type="text"
          className={`w-full h-14 rounded-2xl bg-brandGray text-sm font-normal placeholder:text-textGray outline-0 p-3 px-8 ${locale?.includes("ar-") ? "text-right" : "text-left"}`}
          placeholder={t("client_area_searchbox_searcharea")}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        {searchValue ? (
          <Close
            className={`absolute  ${locale?.includes("ar-") ? "left-5" : "right-5"} top-5 w-5 h-5 border rounded-full p-1 border-textDark cursor-pointer`}
            onClick={() => setSearchValue("")}
          />
        ) : null}
      </div>
      <div className="w-full rounded-xl pb-5 overflow-auto overflow-x-hidden flex-1 no-scrollbar">
        {!searchValue.length ?
          areaType === 1 ?
            <ParentArea areas={areas} action={setSelectedArea} /> :
            <ChildArea areas={areas} action={setSelectedArea} /> :
          <SearchArea areas={areas} areaType={areaType} searchValue={searchValue} action={setSelectedArea} area={selectedArea} />
        }
      </div>
      <div className="w-full  pt-5 pb-1">
        <Button
          onClick={() => { if (selectedArea) action(selectedArea) }}
          isDisabled={selectedArea ? false : true}
          size="slim"
          className="m-auto min-w-[200px] w-max max-w-full h-12 !font-bold text-base rounded-full truncate"
        >
          {selectedArea ? `${t("client_button_continue_to").replace("@", selectedArea ? selectedArea.name : "")}` : t("client_buttons_continue")}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(AreaView);

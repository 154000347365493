import { IAreaDetailInput } from "@types";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React from "react";
import { useEffect, useState } from "react";
import cn from "classnames";

interface SearchChildAreaProps {
  areas: IAreaDetailInput[]
  action: (area: IAreaDetailInput | undefined) => void
  area?: IAreaDetailInput
}

const SearchChildArea: React.FC<SearchChildAreaProps> = ({ areas, action, area: defaultArea }) => {
  const t = useTranslations("");
  const { locale } = useRouter()
  const [selectedArea, setSelectedArea] = useState<undefined | IAreaDetailInput>(defaultArea)
  useEffect(() => {
    action(selectedArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea])

  useEffect(() => {
    setSelectedArea(defaultArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultArea])
  return (
    <div className="w-full">
      {areas.map((area) => (
        <div
          key={area.id}
          className={cn("w-full flex items-center justify-between cursor-pointer px-2 h-14 border-t gap-3", locale?.includes("ar-") ? "flex-row-reverse" : "flex-row")}
          // className="w-full flex items-center justify-between cursor-pointer px-2 h-14 border-b gap-3"
          onClick={() => setSelectedArea(area)}
        >
          <div className=" flex-1 overflow-hidden">
            <p className={cn("text-[#999]  text-sm font-normal whitespace-nowrap text-ellipsis overflow-hidden w-full", locale?.includes("ar-") ? "text-right" : "text-left")}>
              <span className=" text-textDark">{area.name}</span> {t("client_general_in")} {area.parentName}
            </p>
          </div>
          {selectedArea && selectedArea.id === area.id ? <div className="flex justify-end items-center h-6 w-6">
            <div className={`w-6 h-6 border-2 border-[#008AF6] rounded-full flex justify-center items-center`}>
              <div className={`w-4 h-4 rounded-full bg-[#008AF6]`}></div>
            </div>
          </div> : null}
        </div>
      ))}
    </div>
  );
}

export default React.memo(SearchChildArea);



import { useAppContext } from "@context/app";
import BagIcon from "@svg/BagIcon";
import { cartViewPaths } from "@utils/constants";
import { toArabicNumber } from "@utils/digits";
import { segmentEvent } from "@utils/segment";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import MobileMenu from "./mobileMenu";
import SearchIcon from "@svg/SearchIcon";

interface MobileHeaderProps {
  openCountryModal: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ openCountryModal }) => {
  const { country, cart, user, eventProperties, freeShipping } = useAppContext();
  const { locale, pathname } = useRouter();

  const ItemsQty = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty;
  }, 0);
  const totalPrice = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty * (currentValue.raw_price || currentValue.price);
  }, 0);
  return (
    <div className="h-[70px] px-3" id="dabdoob-header-layer1">
      <div className="m-auto w-full flex items-center h-full">
        <div className="flex-1">
          <div className="header-dropdowns font2 flex  items-center ">
            <MobileMenu />
            {country && (
              <div
                className="h-max w-max mx-3 flex items-center justify-center cursor-pointer"
                onClick={() => openCountryModal()}
              >
                <div className="w-6 h-6 rounded-full overflow-hidden relative border-2 ">
                  <Image
                    src={country?.image.url || ""}
                    alt={country?.name || ""}
                    layout="fill"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="">
          <div className="max-w-[120px]">
            <Link href="/" passHref prefetch={false}>
              <a>
                <Image src="/svgs/logo.svg" alt="dabdoob" width="94" height="23.5" />
              </a>
            </Link>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-end">
          <div className="w-max mx-3">
            <Link href="/search" passHref prefetch={false}>
              <a aria-label="search">
                <div className="">
                  <SearchIcon
                    stroke="#333"
                    className=" cursor-pointer"
                  />
                </div>
              </a>
            </Link>
          </div>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a
            href="/cart"
            aria-label="Shopping Cart"
            onClick={() => {
              if (cartViewPaths.includes(pathname)) {
                segmentEvent("Cart Viewed", {
                  user_id: user.data?.id || -1,
                  country_id: country?.id,
                  country_slug: country?.slug,
                  language: locale?.includes("en") ? "English" : "Arabic",
                  total_items: ItemsQty,
                  total_skus: cart.length,
                  subtotal_amount: totalPrice,
                  free_shipping_threshold: freeShipping ?? undefined,
                  ...eventProperties
                })
              }
            }

            }>
            <div className="z-[20] relative">
              <BagIcon className="cursor-pointer m-auto" />
              {ItemsQty ? (
                <div className="w-4 h-4 rounded-full bg-brandBlue absolute flex justify-center items-center -top-1 -right-1  ">
                  <span className=" text-white font-normal text-xs ">
                    {locale?.includes("ar") ? toArabicNumber(ItemsQty) : ItemsQty}
                  </span>
                </div>
              ) : null}
            </div>
          </a>
        </div>
      </div>
    </div >
  );
};

export default MobileHeader;

import { IAreaDetailInput, IAreaInput } from "@types";
import { useEffect, useState } from "react";
import ParentArea from "./parentArea";
import SearchChildArea from "./searchChildArea";
import React from "react";
import AddressesIcon from "@svg/AddressesIcon";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

interface SearchAreaProps {
  areas: IAreaInput[];
  areaType?: 0 | 1 | 2;
  searchValue: string;
  action: (area: IAreaDetailInput | IAreaInput | undefined) => void
  area?: IAreaDetailInput | IAreaInput | undefined
}

const SearchArea: React.FC<SearchAreaProps> = ({ areas, areaType, searchValue, action, area }) => {
  const t = useTranslations("");
  const { locale } = useRouter()
  const [childSearchResult, setChildSearchResult] = useState<IAreaDetailInput[]>([])
  const [parentSearchResult, setParentSearchResult] = useState<IAreaInput[]>([])
  useEffect(() => {
    action(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (searchValue.length) {
      if (areaType === 1) {
        const list = [];
        for (const area of areas) {
          if (area.name.toLowerCase().includes(searchValue.toLowerCase())) {
            list.push(area);
          }
        }
        action(undefined)
        // if (!list.length || !list.find(a => a.id === area?.id)) {
        //   action(undefined)
        // }
        setParentSearchResult(list);
      } else {
        const list = [];
        for (const area of areas) {
          for (const child of area.items) {
            if (child.name.toLowerCase().includes(searchValue.toLowerCase())) {
              list.push({ ...child, parentName: area.name });
            }
          }
        }
        action(undefined)
        // if (!list.length || !list.find(a => a.id === area?.id)) {
        //   action(undefined)
        // }
        setChildSearchResult(list);
      }

    } else {
      setParentSearchResult([])
      setChildSearchResult([])
      action(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])
  const length = areaType === 1 ? parentSearchResult.length : childSearchResult.length
  return (
    <div className="w-full">
      {length ? areaType === 1 ? <ParentArea areas={parentSearchResult} action={action} area={area as any} /> : <SearchChildArea areas={childSearchResult} action={action} area={area as any} /> : <div className="w-full flex flex-col items-center justify-center min-h-[250px]">
        <AddressesIcon className=" stroke-textLight mb-3" />
        <p dir={locale?.includes("ar-") ? "rtl" : "ltr"} className="text-textLight text-sm font-normal w-[220px] leading-6">
          {t("client_area_nodatamessage")}
        </p>
      </div>}
    </div>
  );
}

export default React.memo(SearchArea);


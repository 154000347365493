import { useAppContext } from "@context/app";
import {
  IActiveOrderItem,
  IBrandInput,
  ICategoryInput,
  IOrderDetailInput,
  ISkuModel,
} from "@types";
import { segmentEvent } from "@utils/segment";
import { useRouter } from "next/router";

export interface ISourceEvent {
  from_page: string;
  from_entity: string;
  from_entity_slug: string;
  from_page_section: string;
  from_page_section_entity: string;
  from_page_section_slug: string;
}

function useEvents() {
  const { user, country, area, currency_iso, cart, freeShipping } = useAppContext();
  const { locale } = useRouter();
  const ItemsQty = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty;
  }, 0);
  const totalPrice = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty * (currentValue.raw_price || currentValue.price);
  }, 0);

  const videoListViewed = (source: ISourceEvent) => {
    segmentEvent("Videos List Viewed", {
      language: locale?.includes("en") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      ...source,
    });
  };

  const skuVideoPlayed = (
    source: ISourceEvent,
    sku: ISkuModel,
    scroll: "next" | "previous" | "first" | "",
    product?: { slug: string; id: number }
  ) => {
    segmentEvent("SKU Video Played", {
      language: locale?.includes("en") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      product_id: sku.product?.id ?? product?.id,
      product_slug: sku.product?.slug ?? product?.slug,
      sku_id: sku.id,
      sku_slug: sku.slug,
      scroll,
      out_of_stock: sku.buy_limit ? false : true,
      ...source,
    });
  };

  const skuAdded = (
    source: ISourceEvent,
    sku: ISkuModel,
    product?: {
      slug: string;
      id: number;
      category?: ICategoryInput;
      brand?: IBrandInput;
      subcategory?: ICategoryInput;
    }
  ) => {
    const brand_id = sku.product?.brand.id ?? product?.brand?.id;
    const brand_slug = sku.product?.brand.slug ?? product?.brand?.slug;
    const category_id = sku.product?.category.id ?? product?.category?.id;
    const category_slug = sku.product?.category.slug ?? product?.category?.slug;
    const subcategory_id = sku.product?.subcategory?.id ?? product?.subcategory?.id ?? -1;
    const subcategory_slug = sku.product?.subcategory?.slug ?? product?.subcategory?.slug ?? "";
    segmentEvent("SKU Added", {
      product_id: sku.product?.id ?? product?.id,
      product_slug: sku.product?.slug ?? product?.slug,
      sku_id: sku?.id,
      sku_slug: sku?.slug,
      brand_id,
      brand_slug,
      category_id,
      category_slug,
      subcategory_id,
      subcategory_slug,
      sku_old_price: sku.price.old_price ?? sku.price.price,
      sku_new_price: sku.price.price,
      price: sku?.price.price,
      currency: currency_iso.toUpperCase(),
      cart_count: ItemsQty + 1,
      quantity: ItemsQty + 1,
      language: locale?.includes("en-") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      subtotal_amount: totalPrice + (sku.price.raw_price || sku.price.price),
      free_shipping_threshold: freeShipping ?? undefined,
      ...source,
    });
  };

  const skuRemoved = (
    source: ISourceEvent,
    sku: ISkuModel,
    action: "Remove" | "Delete",
    product?: {
      slug: string;
      id: number;
      category?: ICategoryInput;
      brand?: IBrandInput;
      subcategory?: ICategoryInput;
    }
  ) => {
    const brand_id = sku.product?.brand.id ?? product?.brand?.id;
    const brand_slug = sku.product?.brand.slug ?? product?.brand?.slug;
    const category_id = sku.product?.category.id ?? product?.category?.id;
    const category_slug = sku.product?.category.slug ?? product?.category?.slug;
    const subcategory_id = sku.product?.subcategory?.id ?? product?.subcategory?.id ?? -1;
    const subcategory_slug = sku.product?.subcategory?.slug ?? product?.subcategory?.slug ?? "";
    segmentEvent("SKU Removed", {
      action: action,
      product_slug: sku.product?.slug ?? product?.slug,
      product_id: sku.product?.id ?? product?.id,
      sku_id: sku.id,
      sku_slug: sku.slug,
      brand_id,
      brand_slug,
      category_id,
      category_slug,
      subcategory_id,
      subcategory_slug,
      cart_count: ItemsQty ? ItemsQty - 1 : 0,
      sku_old_price: sku.price.old_price ?? sku.price.price,
      sku_new_price: sku.price.price,
      currency: currency_iso.toUpperCase(),
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      ...source,
      quantity: ItemsQty ? ItemsQty - 1 : 0,
    });
  };

  const cartViewed = (source: ISourceEvent) => {
    segmentEvent("Cart Viewed", {
      total_items: ItemsQty,
      total_skus: cart.length,
      language: locale?.includes("en") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      user_id: user.data?.id || -1,
      subtotal_amount: totalPrice,
      free_shipping_threshold: freeShipping ?? undefined,
      ...source,
    });
  };

  const notifyEvent = (source: ISourceEvent, data: ISkuModel, action: string) => {
    segmentEvent("Availability Alert Updated", {
      product_id: data.product?.id,
      product_slug: data.product?.slug,
      sku_id: data.id,
      sku_slug: data.slug,
      sku_old_price: data.price.old_price ?? data.price.price,
      sku_new_price: data.price.price,
      price: data.price.price,
      out_of_stock: data.buy_limit ? false : true,
      language: locale?.includes("en") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      ...source,
      action,
    });
  };

  const offerCopied = (source: ISourceEvent, code: string) => {
    segmentEvent("Offer Copied", {
      language: locale?.includes("en") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      coupon_code: code,
      ...source,
    });
  };

  const activeOrderBannerClosed = (data: IActiveOrderItem) => {
    segmentEvent("Active Order Banner Closed", {
      language: locale?.includes("en-") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      order_id: data.code,
      from_page: "homepage",
      from_entity: "",
      from_entity_slug: "",
      from_page_section: "",
      from_page_section_entity: "",
      from_page_section_slug: "",
    });
  };

  const orderDetailViewed = (
    data: IOrderDetailInput,
    source: "my_orders" | "active_order_banner"
  ) => {
    let totalItem = 0,
      totalSkus = 0;
    const skus_slug = [];
    const skus_id = [];
    let textQty = 0;
    let wrappingQty = 0;
    let cartQty = 0;
    for (const parcel of data.parcels || []) {
      for (const item of parcel.items) {
        let map = new Array(item.count);
        for (const _ of map) {
          skus_slug.push(item.sku.slug);
          skus_id.push(item.sku.id);
        }
        totalItem += 1;
        totalSkus += item.count;
        textQty += item.gift.filter((g) => g.customtext?.length && g.customtext !== null).length
          ? 1
          : 0;
        wrappingQty += item.gift.filter((g) => g.wrapping !== null)?.length || 0;
        cartQty += item.gift.filter((g) => g.card !== null)?.length || 0;
      }
    }

    segmentEvent("Order Detail Viewed", {
      language: locale?.includes("en-") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      skus_slug_list: skus_slug,
      skus_id_list: skus_id,
      total_items: totalSkus,
      total_skus: totalItem,
      total_amount: data?.price_details.find((c) => c.e.n === "total_amount")?.e.v ?? 0,
      revenue: data?.price_details.find((c) => c.e.n === "revenue_amount")?.e.v ?? 0,
      subtotal_amount: data?.price_details.find((c) => c.e.n === "subtotal_amount")?.e.v ?? 0,
      coupon_amount: Math.abs(data?.price_details.find((c) => c.e.n === "coupon_amount")?.e.v ?? 0),
      delivery_amount: data?.price_details.find((c) => c.e.n === "delivery_amount")?.e.v ?? 0,
      tax_amount: data?.price_details.find((c) => c.e.n === "tax_amount")?.e.v ?? 0,
      wallet_amount: data?.price_details.find((c) => c.e.n === "wallet_amount")?.e.v ?? 0,
      discount_amount: data?.price_details.find((c) => c.e.n === "discount_amount")?.e.v ?? 0,
      address_by_phone: data.address.by_phone,
      currency: currency_iso.toUpperCase(),
      order_id: data.code,
      gift_card: cartQty ? true : false,
      gift_wrap: wrappingQty ? true : false,
      paymentmethod_id:
        data?.price_details.find((c) => c.e.n === "total_amount")?.e.v === 0
          ? "-1"
          : data?.price_details.find((c) => c.e.n === "payment_method_id")?.e.v,
      from_page: source,
      from_entity: "",
      from_entity_slug: "",
      from_page_section: "",
      from_page_section_entity: "",
      from_page_section_slug: "",
    });
  };

  const allBrandsViewed = (source: ISourceEvent) => {
    segmentEvent("All Brands Viewed", {
      language: locale?.includes("en-") ? "English" : "Arabic",
      country_id: country?.id,
      country_slug: country?.slug,
      user_id: user.data?.id || -1,
      ...source,
    });
  };

  const permissionPageViewed = () => {
    segmentEvent("FTUE Location Permission Page Open", {
      user_id: -1,
    });
  };

  const confirmPermission = () => {
    segmentEvent("FTUE Location Permission Granted", {
      user_id: -1,
    });
  };
  const rejectPermission = () => {
    segmentEvent("FTUE Location Permission Rejected", {
      user_id: -1,
    });
  };
  const countryPageViewed = () => {
    segmentEvent("FTUE Select Country Page Open", {
      user_id: -1,
    });
  };
  const areaPageViewed = (
    country_id: string | number,
    country_slug: string,
    language: "en" | "ar"
  ) => {
    segmentEvent("FTUE Select Area Page Open", {
      user_id: -1,
      language: language === "en" ? "English" : "Arabic",
      country_id,
      country_slug,
    });
  };

  const openMainMenu = (
    country_id: string | number,
    country_slug: string,
    area_id: string | number,
    area_slug: string,
    language: "en" | "ar"
  ) => {
    segmentEvent("FTUE Main Menu Open", {
      user_id: -1,
      language: language === "en" ? "English" : "Arabic",
      country_id,
      country_slug,
      area_id,
      area_slug,
    });
  };

  return {
    videoListViewed,
    skuVideoPlayed,
    skuAdded,
    skuRemoved,
    cartViewed,
    notifyEvent,
    offerCopied,
    activeOrderBannerClosed,
    orderDetailViewed,
    allBrandsViewed,
    permissionPageViewed,
    confirmPermission,
    rejectPermission,
    countryPageViewed,
    areaPageViewed,
    openMainMenu,
  };
}

export default useEvents;

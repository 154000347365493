import { IProductOutput } from "@types";

// eslint-disable-next-line no-useless-escape
export const numberPattern = /^-?\d*\.?\d+$/;
// eslint-disable-next-line no-useless-escape
export const positiveNumberPattern = /^\d+\.?\d*$/;
export const positiveIntNumberPattern = /^\d+$/;

export const COOKIE_LOCALE_KEY = "DAB_LOCALE";
export const NEXT_LOCALE_KEY = "NEXT_LOCALE";
export const COOKIE_REFRESH_TOKEN_KEY = "DB_REFRESH_TOKEN";
export const COOKIE_ACCESS_TOKEN_KEY = "DB_ACCESS_TOKEN";
export const COOKIE_LOGIN_TOKEN_KEY = "DB_LOGIN_TOKEN";
export const COOKIE_EXPIRATION_TIME = 365;
export const COOKIE_COUNTRY_KEY = "DAB_COUNTRY";
export const COOKIE_AREA_KEY = "DAB_AREA";
export const COOKIE_CONFIRM_LOCATION = "CONFIRM_LOCATION";
export const COOKIE_UID_KEY = "DAB_UID_KEY";
export const COOKIE_IS_OLD_USER = "IS_OLD_USER";
export const COOKIE_IS_CONFIRM_LOCATION = "IS_CONFIRM_LOCATION";

export const AUTHENTICATION_URL = "/signin";
export const SITE_URL = "https://dabdoob.com";
export const SITE_ORIGIN = process.env.NEXT_PUBLIC_SITE_ORIGIN || new URL(SITE_URL).origin;

export const BRAND_NAME = "Dabdoob";
export const SITE_NAME = "Dabdoob";
export const COPY_RIGHT = "©2023 Dabdoob. All Rights Reserved";
export const DEFAULT_IMAGE =
  "https://dabdoobcdn.fra1.cdn.digitaloceanspaces.com/branding/main-logo-white.png";

export const breakPoints = {
  400: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 6,
    spaceBetween: 10,
  },
};

export const countryBreakPoints = {
  320: {
    slidesPerView: 4,
    spaceBetween: 1,
  },
  425: {
    slidesPerView: 5,
    spaceBetween: 1,
  },
  640: {
    slidesPerView: 5,
    spaceBetween: 1,
  },
  768: {
    slidesPerView: 5,
    spaceBetween: 1,
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 1,
  },
};

export const mostPopular: IProductOutput = { sort: { key: "sold", value: -1 } };
export const newest: IProductOutput = { sort: { key: "CDate", value: -1 } };

export const cartViewPaths = ["/", "/product/[slug]", "/product", "/settings", "/videos"];

export const productViewed_fromPage = [
  "homepage",
  "product_details",
  "search",
  "search_list",
  "product_list",
  "cart",
  "checkout",
  "customizations",
  "order_details",
  "share",
  "video_feed",
  "notify_list",
];

export const productViewed_fromEntity = [
  "home",
  "product",
  "collection",
  "category",
  "brand",
  "attribute",
  "tag",
  "sku",
];

export const productViewed_fromPageSection = [
  "Related Products",
  "Popular Searches",
  "Search Results",
];

export const productViewed_fromPageSectionEntity = ["slider", "widget", "collection"];

export const video_filter: IProductOutput = {
  filters: [
    {
      key: "video_sku",
      type: "boolean",
      operation_type: "include",
      value: true,
    },
  ],
  sort: { key: "media_sku_video", value: -1 },
};

export const filterApplied_fromEntity = [
  "collection",
  "category",
  "brand",
  "attribute",
  "tag",
  "search",
];

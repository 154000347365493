import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import cn from "classnames";
import { useRouter } from "next/router";
import { BsX } from "react-icons/bs";
import Button from "@elements/button";
import { useAppContext } from "@context/app";
import Image from "next/image";
import { useTranslations } from "next-intl";
import _ from "lodash";
import BagIcon from "@svg/BagIcon";
import { CartItemModel } from "@types";
import { convertNumber, convertStringNumber, toArabicNumber } from "@utils/digits";
import Link from "next/link";
import { segmentEvent } from "@utils/segment";
import { cartViewPaths } from "@utils/constants";
import FreeDelivery from "@elements/freeDelivery";

interface Props {
  className?: string;
}

const ShoppingCart: React.FC<Props> = ({ className }) => {
  const t = useTranslations("");
  const { push, locale, reload, pathname } = useRouter();

  const {
    setUserCart,
    cart,
    currency,
    decimal,
    country,
    currency_iso,
    user,
    eventProperties,
    area,
    freeShipping
  } = useAppContext();


  const goToCartView = (close: () => void) => {
    if (cartViewPaths.includes(pathname)) {
      segmentEvent("Cart Viewed", {
        user_id: user.data?.id || -1,
        country_id: country?.id,
        country_slug: country?.slug,
        language: locale?.includes("en") ? "English" : "Arabic",
        total_items: ItemsQty,
        total_skus: cart.length,
        subtotal_amount: totalRawPrice,
        free_shipping_threshold: freeShipping ?? undefined,
        ...eventProperties
      })
    }
    push("/cart").finally(() => {
      reload();
    });
    close();
  };

  const removeItemFromCart = (item: CartItemModel) => {
    const _cart = cart.filter((sku) => sku.id !== item.id);
    segmentEvent("SKU Removed", {
      action: "Delete",
      product_slug: item.productSlug,
      product_id: item.productId,
      brand_id: item.brand_id,
      brand_slug: item.brand_slug,
      category_id: item.category_id,
      category_slug: item.category_slug,
      subcategory_id: item.subcategory_id,
      subcategory_slug: item.subcategory_slug,
      sku_id: item.id,
      sku_slug: item.skuSlug,
      currency: currency_iso.toUpperCase(),
      country_id: country?.id,
      country_slug: country?.slug,
      area_id: area?.id,
      area_slug: area?.slug,
      user_id: user.data?.id || -1,
      language: locale?.includes("en") ? "English" : "Arabic",
      sku_old_price: item.oldPrice ?? item.price,
      sku_new_price: item.price,
      from_page: "cart",
      from_entity: "",
      from_entity_slug: "",
      from_page_section: "",
      from_page_section_entity: "",
      from_page_section_slug: "",
      cart_count: ItemsQty ? (ItemsQty - item.qty) : 0,
      quantity: ItemsQty ? (ItemsQty - item.qty) : 0
    });
    setUserCart([..._cart]);
  };

  const ItemsQty = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty;
  }, 0);

  const totalPrice = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty * currentValue.price;
  }, 0);
  const totalRawPrice = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty * (currentValue.raw_price || currentValue.price);
  }, 0);
  let giftsPrice = 0;
  _.forEach(cart, (value) => {
    _.forEach(value.gifts, (v) => {
      giftsPrice += v.wrapping?.price.price || 0;
    });
  });

  return (
    <Popover className={cn("relative", className)} as="nav">
      {({ open, close }) => (
        <>
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="fixed inset-0 backdrop-brightness-50 z-[60]" />
          </Transition>
          <Popover.Button className=" z-[20] relative focus:outline-none h-full w-full border rounded-xl" aria-label="Cart">
            <BagIcon className="cursor-pointer m-auto" />
            {ItemsQty ? (
              <div className="w-5 h-5 rounded-full bg-brandBlue absolute flex justify-center items-center top-6 right-0 ">
                <span className=" text-white font-normal text-xs ">
                  {locale?.includes("ar-") ? toArabicNumber(ItemsQty) : ItemsQty}
                </span>
              </div>
            ) : null}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom={`opacity-0  ${locale?.includes("ar") ? "-translate-x-full" : "translate-x-full"
              }`}
            enterTo={`opacity-100  ${locale?.includes("ar") ? "-translate-x-0" : "translate-x-0"
              }`}
            leave="transition ease-in duration-150"
            leaveFrom={`opacity-100  ${locale?.includes("ar") ? "-translate-x-0" : "translate-x-0"
              }`}
            leaveTo={`opacity-0  ${locale?.includes("ar") ? "-translate-x-full" : "translate-x-full"
              }`}
          >
            <Popover.Panel className={` flex flex-col justify-between fixed min-h-screen top-[0px] z-[60] ${locale?.includes("ar") ? "left-0" : "right-0"} w-screen overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5 max-w-xs  bg-white transform`}>
              <div className={cn("w-full flex flex-wrap justify-between items-center h-14 fixed top-0 p-3 bg-white z-10", cart.length ? "" : "shadow")}>
                <p className=" font-normal text-xl">{t("w-shoppingCart")}</p>
                <BsX size={30} onClick={() => close()} />
              </div>
              <div className="w-full mt-[60px] pb-5 h-[calc(100vh-200px)] overflow-auto relative no-scrollbar">
                {cart.length ? <div className="w-full sticky top-0 z-20 p-2 pt-0 bg-white shadow ">
                  <FreeDelivery />
                </div> : null}
                {cart.map((sku) => (
                  <div
                    className="w-full flex flex-wrap p-3 items-center h-[120px] border-b"
                    dir="ltr"
                    key={sku.id}
                  >
                    <div className=" flex-1 h-full flex flex-col justify-between">
                      <p className="w-full font-bold text-sm text-[#333] py-2 pr-2">
                        {sku.productName.length > 40
                          ? `${convertStringNumber(locale, sku.productName.substring(0, 40))} ...`
                          : `${convertStringNumber(locale, sku.productName)}`}
                      </p>
                      <p className=" font-normal text-sm py-2 text-[#333] left-to-right items-start w-max">
                        {`${convertNumber(locale, sku.qty)} x `}{" "}
                        <span
                          style={{ direction: "rtl" }}
                          className="text-left px-1"
                        >
                          {" "}
                          {currency}{" "}
                        </span>
                        <span>{convertNumber(locale, sku.price.toFixed(decimal))}</span>
                      </p>
                    </div>

                    <div className=" w-24 h-24 relative rounded-xl  border">
                      <Link href={`/product/${sku.productSlug ?? sku.productId}?default_sku=${sku.id}`} passHref prefetch={false}>
                        <a>
                          <Image
                            src={sku.image}
                            layout="fill"
                            alt=""
                            className="rounded-xl "
                          />
                        </a>
                      </Link>

                      <div className=" absolute -top-2 -right-2 z-10 w-7 h-7 shadow bg-white rounded-full flex justify-center items-center ">
                        <p>
                          <BsX
                            size={28}
                            onClick={() => removeItemFromCart(sku)}
                            className=" cursor-pointer"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-wrap justify-between items-center h-28 fixed bottom-0  shadow-[0px_-8px_32px_rgba(0,0,0,0.12)] p-3">
                <div className="flex justify-between w-full p-1 pt-0 pb-4 font-extrabold text-md ">
                  <span>{t("w-subtotal")}:</span>
                  <p className="left-to-right">
                    <span style={{ direction: "rtl" }} className="text-left">
                      {currency}{" "}
                    </span>
                    <span className="px-1">
                      {convertNumber(locale, (totalPrice).toFixed(decimal))}
                    </span>
                  </p>
                </div>
                <Button
                  size="slim"
                  className="w-full h-12 font-extrabold text-base rounded-full mb-2"
                  theme="grayLight"
                  isDisabled={cart.length ? false : true}
                  onClick={() => goToCartView(close)}
                >
                  {t("w-viewCart")}
                </Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ShoppingCart;
